<template>
    <span>
        <button class="btn btn-sm btn-info" :class="{ 'hide': hideButton }" ref="mediaSelectButton" @click="toggleList">
            <i class="fas fa-images"></i>
            {{ $t('gallery.add') }}
        </button>

        <Modal v-if="showList" :title="$t('gallery.add')" @close="toggleList">
            <template v-slot:body>
                <GalleryListView modal @select="select" @detail="detail"/>
            </template>
        </Modal>

        <Modal v-if="showDetail" :title="$t('route.gallery_detail')" @close="toggleDetail" class="modal-scroll">
            <template v-slot:body>
                <GalleryView modal/>
            </template>
        </Modal>
    </span>
</template>

<script>
import Modal from '@/components/shared/Modal'
import GalleryView from '@/views/gallery/GalleryView'
import GalleryListView from '@/views/gallery/GalleryListView'

export default {
  name: 'GallerySelectButton',
  components: {
    Modal,
    GalleryView,
    GalleryListView
  },
  props: {
    article: {
      type: Object
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showList: false,
      showDetail: false
    }
  },
  methods: {
    toggleList () {
      this.showList = !this.showList
    },
    toggleDetail () {
      this.showDetail = !this.showDetail
    },
    async select (gallery) {
      await this.$store.dispatch('gallery/fetchOne', gallery.id)
      this.article.expanded.gallery = [...this.$store.getters['gallery/detail'].expanded.medias, ...this.article.expanded.gallery]
      this.article.expanded.gallery.map((item, index) => {
        item.position = index
      })
      this.article.galleryGroup = gallery.id
      this.toggleList()
    },
    async detail (gallery) {
      await this.$store.dispatch('gallery/fetchOne', gallery.id)
      this.toggleDetail()
    }
  }
}
</script>

<style lang="scss">
    .modal-scroll .modal-content {
        max-height: calc(100vh - 60px);
    }
    .modal-scroll .modal-body {
        overflow: auto;
    }
</style>

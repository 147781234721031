<template>
  <section>
    <label :class="{'error': error}">
      {{ $t('article.nature_of_the_article') }}
      <span class="required">*</span>
    </label><br>
    <app-checkbox-button
      v-model="article.setting.editorialArticle"
      id="article_editorialArticle"
      data-test="article_editorialArticle"
      :label="$t('article.editorial_article')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-if="!vlmDotyk"
      v-model="article.flag.native"
      id="article_flag_native"
      data-test="article_flag_native"
      :label="$t('article.native')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="article.setting.prArticle"
      id="article_prArticle"
      data-test="article_prArticle"
      :label="$t('article.pr')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import CheckboxButton from '../form/inputs/InputCheckBox'

export default {
  name: 'NatureOfTheArticle',
  props: {
    article: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['vlmDotyk']),
    native () {
      return this.article.flag.native
    },
    prArticle () {
      return this.article.setting.prArticle
    },
    editorialArticle () {
      return this.article.setting.editorialArticle
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  watch: {
    native (val) {
      if (val) {
        this.article.flag.native = true
        this.article.setting.prArticle = false
        this.article.setting.editorialArticle = false
      }
    },
    prArticle (val) {
      if (val) {
        this.article.flag.native = false
        this.article.setting.prArticle = true
        this.article.setting.editorialArticle = false
      }
    },
    editorialArticle (val) {
      if (val) {
        this.article.flag.native = false
        this.article.setting.prArticle = false
        this.article.setting.editorialArticle = true
      }
    }
  },
  components: {
    appCheckboxButton: CheckboxButton
  }
}
</script>

<style lang="scss">

</style>

<template>
  <section>
    <label :class="{'error': error}">
      {{ $t('article.safety_topics') }}
      <span class="required">*</span>
    </label><br>
    <template v-for="(safetyTopic, index) in safetyTopics">
      <label
        :key="`item-${index}`"
        class="btn btn-secondary m-r-5 m-b-5"
        :class="{ 'active': isSafetyTopicActive(safetyTopic.id) }"
        :data-test="'safety_topic_' + safetyTopic.id"
      >
        <input
          :key="`itemX-${index}`"
          type="checkbox"
          v-model="article.safetyTopics"
          :value="safetyTopic.id"
          :disabled="articleEditDisabled"
          @change="checkSafetyTopics"
        >
        {{ safetyTopic.title }}
      </label>
    </template>
  </section>
</template>

<script>
export default {
  name: 'SafetyTopics',
  props: {
    article: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    safetyTopics () {
      return this.$store.getters['safetyTopic/all']
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    isSafetyTopicActive (id) {
      if (this.article.safetyTopics.indexOf(id) > -1) {
        return true
      }
    },
    checkSafetyTopics (event) {
      if (parseInt(event.target.value) === 1) {
        this.article.safetyTopics = [1]
      } else {
        this.article.safetyTopics = this.article.safetyTopics.filter(safetyTopic => safetyTopic > 1)
      }
    }
  }
}
</script>

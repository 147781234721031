<template>
  <app-modal
    :title="$t('article.modal.before_close_header_1')"
    modal-size="modal-md"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      {{ $t('article.modal.before_close_header_2') }}
    </template>

    <!-- Footer -->
    <template slot="footer">
      <div class="text-center">
        <button
          type="button"
          class="btn btn-danger"
          @click="leave"
          data-test="before_route_leave_btn_yes"
        >
          {{ $t('article.modal.before_close_yes') }}
        </button>
        <button
          type="button"
          class="btn btn-inverse"
          @click="close"
          data-test="before_route_leave_btn_no"
        >
          {{ $t('article.modal.before_close_no') }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'

export default {
  name: 'BeforeRouteLeaveModal',
  components: {
    appModal: Modal
  },
  methods: {
    close () {
      this.$emit('close')
    },
    leave () {
      this.$emit('leave')
    }
  }
}
</script>

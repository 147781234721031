<template>
  <div id="article_page_titles">
    <div v-for="(title, titleIndex) in titles" :key="`item-${titleIndex}`" class="row">
      <div class="col-lg-1 text-right"><label class="m-t-5">{{ titleIndex + 1 }}:</label></div>
      <div class="col-lg-11">
        <app-advanced-input
          v-model="titles[titleIndex]"
          showButtons
          :buttons="inputDeleteButtons"
          @input-button-clicked="removeTitle($event, titleIndex)"
          no-label
        >
        </app-advanced-input>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-success" @click="addTitle()">
        <i class="fas fa-plus"></i> {{ $t('article.page_title_add') }}
      </button>
    </div>
  </div>
</template>
<script>
import AdvancedInput from '../form/inputs/InputAdvanced'

export default {
  name: 'PageTitles',
  props: {
    titles: {
      type: Array,
      default: () => []
    }
  },
  components: {
    appAdvancedInput: AdvancedInput
  },
  data () {
    return {
      inputDeleteButtons: [{ class: 'btn btn-danger', html: '<i class="fas fa-trash"></i>' }]
    }
  },
  methods: {
    addTitle () {
      this.titles.push('')
    },
    removeTitle (buttonIndex, dataIndex) {
      // we have only one button, so we don't need to care of multiple buttons indexes
      this.titles.splice(dataIndex, 1)
    }
  }
}
</script>

<style lang="scss">

</style>

<template>
  <Modal
    :title="$t('article.modal.unsaved_version.header')"
    modal-size="modal-md"
    :show-close-button="false"
  >
    <template slot="body">
      <Preloader v-if="!username" />
      <div v-else>
        {{ $t('article.modal.unsaved_version.body') }}
        <div class="last-modified-info">
          {{ $t('article.modal.unsaved_version.footer', { username, lastModifiedText }) }}
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="text-center">
        <button
          type="button"
          class="btn btn-inverse"
          @click="$emit('close')"
          data-test="unsaved_version_btn_no"
        >
          {{ $t('article.modal.unsaved_version.no') }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="$emit('confirm')"
          data-test="unsaved_version_btn_yes"
        >
          {{ $t('article.modal.unsaved_version.yes') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import Preloader from '@/components/preloader/Preloader'
import UserService from '@/services/user/UserService'

export default {
  name: 'ArticleLogUnsavedVersionModal',
  props: {
    userId: {
      type: Number,
      required: true
    },
    lastModifiedText: {
      type: String,
      required: true
    }
  },
  components: {
    Modal,
    Preloader
  },
  computed: {
    username () {
      const user = this.$store.getters['user/userById'](this.userId)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return this.userId
    }
  }
}
</script>

<style lang="scss" scoped>
.last-modified-info {
  font-size: rem(14px);
  margin-top: rem(20px);
}
</style>

<template>
  <section>
    <label :class="{'error': error}">
      {{ $t('article.source_type') }}
      <span class="required">*</span>
    </label><br>
    <app-checkbox-button
      v-model="article.setting.internalAuthor"
      id="article_setting_internalAuthor"
      data-test="article_setting_internalAuthor"
      :label="$t('article.source_type_internal_author')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-if="!vlmDotyk"
      v-model="article.setting.agencyArticle"
      id="article_setting_agencyArticle"
      data-test="article_setting_agencyArticle"
      :label="$t('article.source_type_agency_article')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-if="!vlmDotyk"
      v-model="article.setting.copiedFromPrint"
      id="article_setting_copiedFromPrint"
      data-test="article_setting_copiedFromPrint"
      :label="$t('article.copied_from_print')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-if="!vlmDotyk"
      v-model="article.setting.externalAuthor"
      id="article_setting_externalAuthor"
      data-test="article_setting_externalAuthor"
      :label="$t('article.source_type_external_author')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
    <app-checkbox-button
      v-model="article.setting.transferedArticle"
      id="article_setting_transferedArticle"
      data-test="article_setting_transferedArticle"
      :label="$t('article.source_type_transfered_article')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox-button>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import CheckboxButton from '../form/inputs/InputCheckBox'

export default {
  name: 'SourceType',
  props: {
    article: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['vlmDotyk']),
    internalAuthor () {
      return this.article.setting.internalAuthor
    },
    agencyArticle () {
      return this.article.setting.agencyArticle
    },
    copiedFromPrint () {
      return this.article.setting.copiedFromPrint
    },
    externalAuthor () {
      return this.article.setting.externalAuthor
    },
    transferedArticle () {
      return this.article.setting.transferedArticle
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  watch: {
    internalAuthor (val) {
      if (val) {
        this.article.setting.internalAuthor = true
        this.article.setting.agencyArticle = false
        this.article.setting.copiedFromPrint = false
        this.article.setting.externalAuthor = false
        this.article.setting.transferedArticle = false
      }
    },
    agencyArticle (val) {
      if (val) {
        this.article.setting.internalAuthor = false
        this.article.setting.agencyArticle = true
        this.article.setting.copiedFromPrint = false
        this.article.setting.externalAuthor = false
        this.article.setting.transferedArticle = false
      }
    },
    copiedFromPrint (val) {
      if (val) {
        this.article.setting.internalAuthor = false
        this.article.setting.agencyArticle = false
        this.article.setting.copiedFromPrint = true
        this.article.setting.externalAuthor = false
        this.article.setting.transferedArticle = false
      }
    },
    externalAuthor (val) {
      if (val) {
        this.article.setting.internalAuthor = false
        this.article.setting.agencyArticle = false
        this.article.setting.copiedFromPrint = false
        this.article.setting.externalAuthor = true
        this.article.setting.transferedArticle = false
      }
    },
    transferedArticle (val) {
      if (val) {
        this.article.setting.internalAuthor = false
        this.article.setting.agencyArticle = false
        this.article.setting.copiedFromPrint = false
        this.article.setting.externalAuthor = false
        this.article.setting.transferedArticle = true
      }
    }
  },
  components: {
    appCheckboxButton: CheckboxButton
  }
}
</script>

<style lang="scss">

</style>

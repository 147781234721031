<template>
  <div class="geneea-tags">
    <h5 class="geneea-tags__desc">{{ $t('article.tags_editorial') }}</h5>
    <MultiSelect
      v-model="article.expanded.tags"
      :placeholder="$t('type_keyword')"
      :options="editorialTags"
      :loading="loading"
      :custom-label="getLabel"
      :limit="999"
      :disabled="articleEditDisabled"
      :error="error"
      track-by="id"
      id="article_editorial_tag_select"
      @search-change="findTags"
      @remove="onRemoveTag"
    />
  </div>
</template>

<script>
import MultiSelect from '@/components/form/select/MultiSelect'

export default {
  name: 'EditorialTags',
  props: {
    article: {
      type: Object,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MultiSelect
  },
  data () {
    return {
      loading: false,
      editorialTags: [],
      removedTags: {} // map of geneeaId
    }
  },
  computed: {
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    getLabel (tag) {
      return `${tag.title}`
    },
    async findTags (query) {
      if (query) {
        await this.$store.dispatch('tag/fetchByTitle', { query, view: 'article' })
        this.editorialTags = this.$store.getters['tag/list']
      } else {
        this.editorialTags = []
      }
    },
    onRemoveTag (removedTag) {
      this.removedTags[removedTag.geneeaId] = removedTag
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/components/GeneeaTags/GeneeaTags.scss";
</style>

<template>
  <div class="col-12 row hp-topic-offer-item p-r-0">
    <app-select
      class="col-3 flex-justify-end"
      v-model="hpTopicOffer.site"
      :options="sites"
      optionTitle="shortTitle"
      no-empty-value
      :id="`article_hpTopicOffer_${index}_site`"
      :label="$t('article.site')"
      :disabled="articleEditDisabled"
    ></app-select>
    <app-datepicker
      class="col-5 flex-justify-end"
      v-model="hpTopicOffer.date"
      :id="`article_hpTopicOffer_${index}_date`"
      :label="$t('article.hp_topic.date')"
      :disabled="articleEditDisabled"
      type="date"
    ></app-datepicker>
    <app-select
      class="col-2 flex-justify-end"
      v-model="hpTopicOffer.slot"
      :options="slots()"
      no-empty-value
      :id="`article_hpTopicOffer_${index}_slot`"
      :label="$t('article.hp_topic.slot')"
      :disabled="articleEditDisabled"
    ></app-select>
    <div class="col-2 flex-justify-end form-group p-r-0">
      <label>{{ $t('article.hp_topic.approved') }}</label>
      <app-checkbox
        class="m-t-5"
        v-model="hpTopicOffer.approved"
        :id="`article_hpTopicOffer_${index}_approved_on`"
        :disabled="articleEditDisabled"
      ></app-checkbox>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Checkbox from '../form/Checkbox'
import Datepicker from '../form/Datepicker'
import Select from '../form/select/Select'
import { SLOTS, PLUS1_SLOT_SCHEDULE, PLUS1_SLOT_HOLIDAYS_SCHEDULE } from '@/model/HpTopicOffer'

export default {
  name: 'HpTopicOfferItem',
  props: {
    hpTopicOffer: {
      type: Object
    },
    department: {
      type: Number
    },
    index: {
      type: Number
    }
  },
  components: {
    appCheckbox: Checkbox,
    appDatepicker: Datepicker,
    appSelect: Select
  },
  computed: {
    holidays () {
      return this.$store.getters['hpTopicOffer/holidays']
    },
    sites () {
      return this.$store.getters['site/allEnabledSorted']().filter((site) => site.name === 'plus1')
    },
    freeDay () {
      const date = moment(this.hpTopicOffer.date).toDate()
      const holidays = this.holidays[date.getFullYear()]
      return [0, 6].includes((new Date(this.hpTopicOffer.date)).getDay()) || (holidays && holidays.includes(date.toJSON()))
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    slots () {
      const availableDepartments = Object.values(PLUS1_SLOT_SCHEDULE).reduce((r, items) => ({ ...r, ...items }), {})
      const holidayDepartments = Object.values(PLUS1_SLOT_HOLIDAYS_SCHEDULE).reduce((r, items) => ({ ...r, ...items }), {})
      const allSlots = SLOTS.map((v) => ({ id: v, title: v }))
      let slots
      if (this.freeDay) {
        if (holidayDepartments[this.department]) {
          slots = allSlots.filter((slot) => PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot.id] && PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot.id][this.department])
        } else {
          slots = allSlots.filter((slot) => PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot.id])
        }
      } else {
        slots = allSlots.filter((slot) => PLUS1_SLOT_SCHEDULE[slot.id] && (
          this.department === null || availableDepartments[this.department] === undefined || PLUS1_SLOT_SCHEDULE[slot.id][this.department]
        ))
      }
      if (slots.length === 0) {
        return allSlots
      }
      const slotIds = slots.map((slot) => slot.id)
      const shouldUpdateSlot = !slotIds.includes(this.hpTopicOffer.slot)
      if (shouldUpdateSlot) {
        this.hpTopicOffer.slot = slots[0].id
      }
      return slots
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-justify-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.hp-topic-offer-item > div:nth-of-type(n+2) {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}
</style>

<template>
  <div v-if="displayCounselingFlag" class="counseling-flag-wrapper">
    <app-checkbox
      v-model="article.flag.counseling"
      id="article_flag_counseling"
      :label="$t('article.flag.counseling_title')"
      :disabled="articleEditDisabled"
    >
    </app-checkbox>
  </div>
</template>

<script>
import appConfig from '@/config'
import appCheckbox from '@/components/form/Checkbox.vue'

export default {
  name: 'CounselingFlagComponent',
  props: {
    article: {
      type: Object,
      required: true
    },
    articleEditDisabled: {
      type: Boolean,
      required: true
    }
  },
  components: {
    appCheckbox
  },
  data () {
    return {
      environmentFromConfig: appConfig.environment(), // production, dev, staging
      prodRubricWithCounseling: [
        // 'zdravie'
        { title: 'Poradňa zdravia', id: 482 },
        // záhradkár
        { title: 'Zelenina poradňa', id: 508 },
        { title: 'Ovocie poradňa', id: 509 },
        { title: 'Okrasná záhrada poradňa', id: 510 },
        { title: 'Izbové rastliny poradňa', id: 511 },
        { title: 'Všetky rady', id: 507 }
      ],
      devRubricWithCounseling: [
        // zdravie
        { title: 'Poradňa zdravia', id: 535 },
        // záhradkár / parent - 579 poradňa
        { title: 'Zelenina poradňa', id: 581 },
        { title: 'Ovocie poradňa', id: 582 },
        { title: 'Okrasná záhrada poradňa', id: 583 },
        { title: 'Izbové rastliny poradňa', id: 584 },
        { title: 'Všetky rady', id: 580 }
      ]
    }
  },
  computed: {
    displayCounselingFlag () {
      const relevantRubrics = ['production', 'production02'].includes(this.environmentFromConfig)
        ? this.prodRubricWithCounseling
        : this.devRubricWithCounseling
      const rubricIds = relevantRubrics.map(rubric => rubric.id)
      return rubricIds.includes(parseInt(this.article.mainRubric))
    }
  }
}

</script>

<style lang="scss">

.counseling-flag-wrapper {
  margin-bottom: 1rem;
}

</style>

<template>
  <div class="article-ai-tools-wrapper">
    <header>
      <h5>{{$t ('article.source_type_ai_assisted') }}</h5>
      <Tooltip :title="$t ('article.ai_assisted_tooltip')" class="article-ai-tools-tooltip"/>
    </header>
    <div
      v-for="tool in availableAiTools"
      :key="tool.toolId"
      class="ai-tools-checkbox"
    >
      <Checkbox
        v-model="tool.selected"
        :id="`article_setting_${tool.toolId}`"
        :label="tool.name"
        :disabled="articleEditDisabled"
        @input="updateAiToolsUsed"
      />
      <Input
        v-if="tool.toolId === 'OtherAiTool'"
        type="text"
        class="ai-tools-text-input"
        v-model="tool.description"
        :disabled="!tool.selected || articleEditDisabled"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox'
import Tooltip from '@/components/tooltip/Tooltip'
import Input from '@/components/form/inputs/Input'
import { availableAiTools } from '@/model/ValueObject/AiTools'

export default {
  name: 'ArticleAiTools',
  props: {
    article: {
      type: Object,
      required: true
    },
    aiAssistedLocked: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Checkbox,
    Tooltip,
    Input
  },
  data () {
    return {
      availableAiTools: availableAiTools,
      lockAiAssist: false
    }
  },
  computed: {
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    updateAiToolsUsed () {
      if (!this.article.aiToolsUsed) {
        this.$set(this.article, 'aiToolsUsed', [])
      }

      this.availableAiTools.forEach(tool => {
        if (!tool.selected) {
          tool.description = ''
        }
      })

      const selectedTools = this.availableAiTools.filter(tool => tool.selected)
      this.article.aiToolsUsed = selectedTools
      if (!this.lockAiAssist) {
        if (!this.aiAssistedLocked) {
          this.article.setting.aiAssisted = selectedTools.length > 0
        }
      }
    }
  },
  mounted () {
    const selectedTools = this.article.aiToolsUsed ?? []
    this.availableAiTools = this.availableAiTools.map(tool => ({
      ...tool,
      description: selectedTools.find((t) => t.toolId === tool.toolId)?.description,
      selected: selectedTools.some((t) => t.toolId === tool.toolId)
    }))
    if (!this.lockAiAssist) {
      if (!this.aiAssistedLocked) {
        this.article.setting.aiAssisted = selectedTools.length > 0
      }
    }
  },
  created () {
    if (this.article?.setting.aiAssisted) {
      this.lockAiAssist = true
    }
  }
}
</script>

<style lang="scss">

.article-ai-tools-wrapper {
  .ai-tools-text-input {
    label {
      display: none;
    }
    input {
      height: 1.7rem !important;
    }
  }
  header {
    margin-top: 1rem;
    display: flex;

    h5 {
      margin-right: 1rem;
    }

    .article-ai-tools-tooltip {
      font-size: 1.1rem;
    }
  }
}

</style>

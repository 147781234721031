<template>
  <app-modal
    :title="$t('article.modal.recipe_header')"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        v-if="recipes.length"
        @click="addRecipe"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="recipe_btn_select"
        :disabled="selectedRecipe === null"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <app-recipe-filter class="m-b-10" disable-status-filter></app-recipe-filter>
      <app-preloader v-if="callingAPI"></app-preloader>
      <app-data-table
        :data="recipes"
        :totalCount="totalCount"
        :page="page"
        :config="dataTableConfig"
        selectable
        actions-in-blank-window
        :disable-multiple-select="!multiple"
        @page-change="setPageAndGetRecords"
        @select-record="selectRecipe"
      >
      </app-data-table>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import Config from '../../config'
import Preloader from '../preloader/Preloader'
import RecipeFilter from '../recipe/RecipeFilter'
import RecipeFilterModel from '../../model/RecipeFilter'
import DataTable from '../shared/DataTable'
import Modal from '../shared/Modal'

export default {
  name: 'RecipeModal',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      search: this._.cloneDeep(RecipeFilterModel),
      dataTableConfig: {
        fields: {
          id: this.$t('system.id'),
          site: this.$t('modal.site'),
          title: this.$t('modal.title'),
          category: this.$t('modal.category'),
          status: this.$t('modal.status'),
          publishedCount: this.$t('modal.published_count'),
          createdAt: this.$t('system.created_at')
        },
        actions: {
          detail: 'recipe_detail',
          edit: 'recipe_edit'
        },
        render: {
          site: (siteId) => this.$store.getters['site/siteById'](siteId).shortTitle
        }
      },
      selectedRecipe: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    recipes () {
      return this.$store.getters['recipe/list']
    },
    totalCount () {
      return this.$store.getters['recipe/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['recipe/totalCount'] / Config.defaults.limit)
    }
  },
  components: {
    appModal: Modal,
    appRecipeFilter: RecipeFilter,
    appDataTable: DataTable,
    appPreloader: Preloader
  },
  methods: {
    getRecipes () {
      this.$store.dispatch('recipe/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('recipe/setPage', this.page)
      this.getRecipes()
    },
    selectRecipe (recipe) {
      if (this.multiple === false) {
        this.selectedRecipe = null
      }
      this.selectedRecipe = recipe[0]
    },
    addRecipe () {
      this.$emit('set-data', this.selectedRecipe)
      this.$emit('close')
    },
    resetFilters () {
      this.$store.commit('recipe/setPage', 1)
      this.$store.commit('recipe/setFilter', this._.cloneDeep(RecipeFilterModel))
      this.$store.dispatch('recipe/fetch')
    },
    close () {
      this.$emit('close')
    }
  },
  beforeMount () {
    this.resetFilters()
  }
}
</script>

<style lang="scss">

</style>

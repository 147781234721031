<template>
  <app-modal
    :title="$t('article.modal.related_disease_articles_header')"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        @click="addRelatedArticles"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="related_disease_article_btn_select"
        :disabled="selectedArticles.length === 0"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <app-article-list-simple
        :multiple="multiple"
        @close="close"
        @set-data="addRelatedArticles"
        @select-record="selectArticle"
        defaultType="disease"
        :defaultSite="10"
      >
      </app-article-list-simple>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import ArticleListSimple from './ArticleListSimple'

export default {
  name: 'RelatedDiseaseArticlesModal',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataLoaded: false,
      selectedArticles: []
    }
  },
  components: {
    appModal: Modal,
    appArticleListSimple: ArticleListSimple
  },
  methods: {
    selectArticle (articles) {
      this.selectedArticles = articles
    },
    addRelatedArticles () {
      this.$emit('set-data', this.selectedArticles)
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }

  }
}
</script>

<template>
    <span>
        <div
          v-if="showDropZone && !selectedDamMedia"
          class="image_dropzone text-center"
          @click="showVideoListModal"
          data-test="image_dropzone"
        >
            <span>{{ $t('media.select_video') }}</span>
        </div>
        <button
          class="btn btn-sm btn-info"
          :class="{ 'hide': hideButton }"
          @click="showVideoListModal"
        >
            <i class="fas fa-video"></i>
            {{ $t('buttons.select_video') }}
        </button>
        <app-video-list-modal
          v-if="videoListModal"
          @set-data="setVideo"
          @close="closeVideoListModal"
        >
        </app-video-list-modal>
    </span>
</template>

<script>
import VideoListModal from '../video/VideoListModal'

export default {
  name: 'VideoSelectButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    showDropZone: {
      type: Boolean,
      default: false
    },
    selectedDamMedia: {
      type: Object
    }
  },
  data () {
    return {
      videoListModal: false
    }
  },
  components: {
    appVideoListModal: VideoListModal
  },
  methods: {
    showVideoListModal () {
      this.videoListModal = true
    },
    closeVideoListModal () {
      this.videoListModal = false
    },
    setVideo (video) {
      this.$emit('set-video', video)
    }
  }
}
</script>

<style lang="scss">
.image_dropzone {
  width: 100%;
  height: 172px;
  margin-bottom: 5px;
  border: 1px dashed #d9d9d9;
  line-height: 160px;
  background: #ced4da;
  cursor: pointer;
}
</style>

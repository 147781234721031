<template>
    <span>
        <button
          class="btn btn-sm btn-info"
          :class="{ 'hide': hideButton }"
          @click="showYoutubeModal"
        >
            <i class="fas fa-video"></i>
            {{ $t('buttons.select_youtube') }}
        </button>
        <app-youtube-modal
          v-if="youtubeModal"
          @set-data="setVideo"
          @close="closeYoutubeModal"
        >
        </app-youtube-modal>
    </span>
</template>

<script>
import YoutubeModal from './YoutubeModal'

export default {
  name: 'YoutubeSelectButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      youtubeModal: false
    }
  },
  components: {
    appYoutubeModal: YoutubeModal
  },
  methods: {
    showYoutubeModal () {
      this.youtubeModal = true
    },
    closeYoutubeModal () {
      this.youtubeModal = false
    },
    setVideo (youtubeId) {
      this.$emit('set-video', youtubeId)
    }
  }
}
</script>

<style lang="scss">
.image_dropzone {
  width: 100%;
  height: 172px;
  margin-bottom: 5px;
  border: 1px dashed #d9d9d9;
  line-height: 160px;
  background: #ced4da;
  cursor: pointer;
}
</style>

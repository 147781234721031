<template>
  <AbstractFilter
    :on-filter="filterRecipes"
    :on-filter-reset="resetFilter"
    create-new-route-name="recipe_new"
    filter-panel-class="filter-panel-recipe"
  >
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('system.id')"
    />
    <Select
      v-model="filter.site"
      :options="sites"
      :label="$t('modal.site')"
      id="filter_site"
    />
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('modal.title')"
    />
    <Input
      v-model="filter.category"
      id="filter_category"
      :label="$t('modal.category')"
    />
    <Datepicker
      type="date"
      v-model="filter.dateFrom"
      id="filter_orderDateFrom"
      :label="$t('recipe.filter.created_at_from')"
   />
    <Datepicker
      type="date"
      v-model="filter.dateTo"
      id="filter_orderDateTo"
      :label="$t('recipe.filter.created_at_to')"
    />
  </AbstractFilter>
</template>
<script>
import Datepicker from '@/components/form/Datepicker'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import RecipeFilterModel from '@/model/RecipeFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'

export default {
  name: 'RecipeFilter',
  components: {
    AbstractFilter,
    Input,
    Datepicker,
    Select
  },
  computed: {
    sites () {
      return this.$store.getters['site/all'].filter(site => site.enabled)
    }
  },
  data () {
    return {
      filter: this._.cloneDeep(RecipeFilterModel)
    }
  },
  methods: {
    async apply () {
      this.$store.commit('recipe/setFilter', this.filter)
      const { data } = await this.$store.dispatch('recipe/fetch')
      this.$emit('data', data)
    },
    resetFilter () {
      this.filter = this._.cloneDeep(RecipeFilterModel)
      this.apply()
    },
    filterRecipes () {
      this.$store.commit('recipe/setPage', 1)
      this.apply()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['recipe/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-recipe {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>

<template>
  <div class="theme-special-and-series-select__wrapper">
    <SelectInput
      v-model="selectedData.special"
      :label="$t('themeSpecialAndSeries.specials.selectLabel')"
      :options="filteredSpecials"
      optionTitle="name"
      :noEmptyValue="false"
      @input="emitSpecial"
    />
    <SelectInput
      v-model="selectedData.theme"
      :label="$t('themeSpecialAndSeries.theme.selectLabel')"
      :options="filteredThemes"
      optionTitle="name"
      @input="emitTheme"
    />
    <SelectInput
      v-model="selectedData.series"
      :label="$t('themeSpecialAndSeries.series.selectLabel')"
      :options="filteredSeries"
      optionTitle="name"
      @input="emitSeries"
    />
    <app-modal
      v-if="showSpecialConflictModal"
      modal-size="modal-md"
      :title="$t('themeSpecialAndSeries.specials.conflict_modal')"
      class="confirm-delete-modal"
      @close="closeSpecialConflictModal"
    >
      <!-- Body -->
      <template slot="body">
        <div v-for="(tag, index) in missingTags" :key="`${index}_tag}`" class="missingTags-wrapper">
          <p>{{ tag.title }}</p>
          <p>id: {{ tag.id }}</p>
        </div>
      </template>
      <!-- Footer -->
      <template slot="footer">
        <button
          type="button"
          class="btn btn-inverse router-link-active"
          @click="closeSpecialConflictModal"
        >
          Ok
        </button>
      </template>
    </app-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import SelectInput from '@/components/form/select/Select.vue'
import appModal from '@/components/shared/Modal.vue'

export default {
  name: 'ThemeSpecialAndSeriesSelect',
  components: {
    appModal,
    SelectInput
  },
  props: {
    article: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      specials: [],
      themes: [],
      series: [],
      showSpecialConflictModal: false,
      missingTags: [],
      lastValidSpecial: 0,
      selectedData: {
        special: null,
        theme: null,
        series: null
      }
    }
  },
  computed: {
    ...mapGetters('themeSpecialAndSeries', [
      'specialList',
      'themeList',
      'seriesList'
    ]),
    ...mapGetters('site', {
      allSites: 'all',
      siteById: 'siteById'
    }),
    filteredSpecials () {
      const filtered = this.filterItems(this.specialList)
      if (filtered.length === 0) this.$emit('setSpecial', null)
      return filtered
    },
    filteredThemes () {
      const filtered = this.filterItems(this.themeList)
      if (filtered.length === 0) this.$emit('setTheme', null)
      return filtered
    },
    filteredSeries () {
      const filtered = this.filterItems(this.seriesList)
      if (filtered.length === 0) this.$emit('setSeries', null)
      return filtered
    }
  },
  methods: {
    ...mapActions('themeSpecialAndSeries', [
      'fetchSpecialList',
      'fetchThemeList',
      'fetchSeriesList'
    ]),
    resetSpecial () {
      this.$set(this.selectedData, 'special', this.lastValidSpecial || 0)
    },
    closeSpecialConflictModal () {
      this.resetSpecial()
      this.showSpecialConflictModal = false
    },
    emitSpecial () {
      const specialFullObj = this.specialList.find(x => x.id === this.selectedData.special) || null

      if (specialFullObj) {
        const specialTags = specialFullObj?.tags ?? []
        const hasSpecialTag = specialTags.some(tag => {
          const tagIdsInArticle = this.article.expanded.tags.map(articleTag => articleTag.id)
          return tagIdsInArticle.includes(tag.id)
        })

        if (hasSpecialTag || specialFullObj.tags.length === 0) {
          this.lastValidSpecial = this.specialList?.find(x => x.id === this.article?.specialArticleGroupId)?.id ?? 0
          this.$emit('setSpecial', specialFullObj)
        } else {
          this.missingTags = specialFullObj.tags
          this.showSpecialConflictModal = true
        }
      } else {
        this.$emit('setSpecial', null)
        this.lastValidSpecial = 0
      }
    },
    emitTheme () {
      const themeFullObj = this.themeList.find(x => x.id === this.selectedData.theme) || null
      this.$emit('setTheme', themeFullObj)
    },
    emitSeries () {
      const seriesFullObj = this.seriesList.find(x => x.id === this.selectedData.series) || null
      this.$emit('setSeries', seriesFullObj)
    },
    async getSpecials () {
      await this.fetchSpecialList({ fetchAll: true })
      this.specials = this.specialList
    },
    async getThemes () {
      await this.fetchThemeList({ fetchAll: true })
      this.themes = this.themeList
    },
    async getSeries () {
      await this.fetchSeriesList({ fetchAll: true })
      this.series = this.seriesList
    },
    filterItems (items) {
      const getSite = this.siteById(this.article.site)
      return items.filter(item => {
        const isItemSiteIdEqualToArticleSiteId = item.siteId === this.article.site
        let itemSiteHasParent = false
        if (getSite && item.forChildSites) {
          itemSiteHasParent = item.siteId === getSite.parentSiteId
        }
        return isItemSiteIdEqualToArticleSiteId || itemSiteHasParent
      })
    },
    getData () {
      if (this.article.specialArticleGroupId) {
        this.selectedData.special = this.article.specialArticleGroupId
      }
      if (this.article.themeId) {
        this.selectedData.theme = this.article.themeId
      }
      if (this.article.seriesId) {
        this.selectedData.series = this.article.seriesId
      }
    }
  },
  updated () {
    this.getData()
  },
  mounted () {
    this.getSpecials()
    this.getThemes()
    this.getSeries()
  }
}
</script>

<style lang="scss" scoped>

.missingTags-wrapper {
  display: inline-block;
  background: #6599fe;
  color: #fff;
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  border-radius: 5px;
  p {
    margin: 0;
    padding: 0;
  }
}

</style>

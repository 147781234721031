<template>
  <span v-if="article.originalDocumentId" class="m-r-10">
    {{ $t('topicOffer.original.content_ratio') }}:
    <Tooltip :title="$t('topicOffer.original.tooltip.content_ratio')" />
    {{ article.originalContentRatio | formatNumber }} %
    <span v-if="article.originalDraftContentRatio" >
      {{ `(${$t('article.status_published')}) ${$t('and')}` }}
      {{ article.originalDraftContentRatio | formatNumber }} %
      {{ `(${$t('article.status_draft')})` }}
    </span>
  </span>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip'
import levenshtein from 'levenshtein-edit-distance'
import { mapState } from 'vuex'
import Vue from 'vue'
import NotifyService from '@/services/NotifyService'
import CoreApi from '@/api/core'

export default {
  name: 'ArticleOriginalContentRatio',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  components: {
    Tooltip
  },
  computed: {
    ...mapState({
      currentBodyBlocks: state => state.nmhEditorStore.data.blocks
    })
  },
  methods: {
    getUsableContent (text) {
      return text.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+>|\n| {2,}|###[^#]+###/g, '').trim()
    },
    getLevenshteinDistance (originalText, newText) {
      newText = this.getUsableContent(newText)
      originalText = this.getUsableContent(originalText)

      return (1 - (levenshtein(originalText, newText) / Math.max(originalText.length, newText.length))) * 100
    },
    getOriginalContentRatio (originalArticle) {
      const convertBlocks = blocks => JSON.stringify(blocks.map(block => {
        const convertedBlock = {
          ...block
        }
        delete convertedBlock.id
        return convertedBlock
      }))

      if (!originalArticle.bodyBlocks) {
        NotifyService.setErrorMessage('Original article body blocks not found!')
        return 0
      }
      return this.getLevenshteinDistance(
        originalArticle.field.title + convertBlocks(originalArticle.bodyBlocks),
        this.article.field.title + convertBlocks(this.currentBodyBlocks)
      )
    },
    /**
     * Sets originalContentRatio and returns originalArticle.
     */
    async setOriginalContentRatio (originalDocumentId) {
      const foundArticles = (await CoreApi().get(`/article/${originalDocumentId}/content-ratio-data`)).data
      let originalArticle
      if (foundArticles.length === 0) {
        NotifyService.setErrorMessage('Original article not found!')
      } else if (foundArticles.length === 1) {
        originalArticle = foundArticles[0]
        this.article.originalContentRatio = this.getOriginalContentRatio(originalArticle)
      } else {
        originalArticle = foundArticles.find(article => article.setting.status === 'published')
        this.article.originalContentRatio = this.getOriginalContentRatio(originalArticle)
        const originalDraftArticle = foundArticles.find(article => article.setting.status === 'draft')
        if (originalDraftArticle) {
          // this is not saved in core-cms (only for Admin)
          const originalDraftContentRatio = this.getOriginalContentRatio(originalDraftArticle)
          Vue.set(this.article, 'originalDraftContentRatio', originalDraftContentRatio)
        }
      }
      return originalArticle
    }
  }
}
</script>

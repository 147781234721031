<template>
  <div>
    <small
      v-if="isSeoTitleInvalid"
      class="form-control-feedback text-warning float-right"
    >
      <i class="fa fa-exclamation-triangle"></i>
      {{ $t('article.seo_title_invalid', { min: seoTitleLimit.min, max: seoTitleLimit.max }) }}
    </small>
    <app-input
      :value="article.field.subTitle"
      @input="article.field.subTitle = removeWhiteSpace($event)"
      :valueSuffix="seoSiteSuffix"
      id="seo_title"
      :label="$t('article.sub_title')"
      show-counter
      required
      :maxlength="250"
      :disabled="articleEditDisabled"
      :class="{'error': transitionErrors.field.subTitle}"
    >
      <app-tooltip
        :title="$t('article.seo_title_seo_info',{ min: seoTitleLimit.min, max: seoTitleLimit.max } )"
        icon="fab fa-google"
        customInfoClass="seo"
      ></app-tooltip>
    </app-input>
    <div class="preview" v-if="seoSiteSuffix.length">
      {{ article.field.subTitle }}<span>{{ seoSiteSuffix }}</span>
    </div>
  </div>
</template>

<script>
import Input from '../form/inputs/Input'
import Tooltip from '../tooltip/Tooltip'
import RemoveWhiteSpaceService from '@/services/RemoveWhiteSpaceService'
import ArticleValidationService from '@/services/article/ArticleValidationService'

export default {
  name: 'SeoTitle',
  props: {
    article: Object,
    transitionErrors: Object,
    fieldsCharsLimit: {
      required: true,
      type: Object
    }
  },
  components: {
    appInput: Input,
    appTooltip: Tooltip
  },
  data () {
    return {
      removeWhiteSpace: RemoveWhiteSpaceService
    }
  },
  computed: {
    company () {
      return this.$store.getters.company
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    seoSiteSuffix () {
      const site = this.$store.getters['site/siteById'](this.article.site)
      return site?.name === 'trend' ? ArticleValidationService.trendSeoTitleSuffix : ''
    },
    seoTitleLimit () {
      return this.fieldsCharsLimit[this.company].seoTitle
    },
    isSeoTitleInvalid () {
      const seoTitleLength = this.article.field.subTitle.length + this.seoSiteSuffix.length
      return (seoTitleLength < this.seoTitleLimit.min ||
        seoTitleLength > this.seoTitleLimit.max)
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  margin: -15px 0 10px;
  font-size: 20px;
  color: #1a0dab;
  overflow: auto;

  div {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }

  span {
    white-space: nowrap;
  }
}
</style>

<template>
    <span>
        <button
          @click="open"
          class="btn btn-sm btn-default"
          :title="$t('article.article_history')"
        >
            <i class="fas fa-history"></i>
        </button>
        <app-article-history-modal
          v-if="articleHistoryModal"
          :article="article"
          @close="close"
        >
        </app-article-history-modal>
    </span>
</template>
<script>
import ArticleHistoryModal from './ArticleHistoryModal'

export default {
  name: 'ArticleHistoryButton',
  props: {
    article: {
      type: Object
    }
  },
  data () {
    return {
      articleHistoryModal: false
    }
  },
  components: {
    appArticleHistoryModal: ArticleHistoryModal
  },
  methods: {
    open () {
      this.articleHistoryModal = true
    },
    close () {
      this.articleHistoryModal = false
    }
  }
}
</script>

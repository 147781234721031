<template>
  <app-modal
    :title="$t('article.modal.save_failed.header')"
    modal-size="modal-md"
    @close="$emit('close')"
  >
    <template slot="body">
      {{ $t('article.modal.save_failed.body', { lastModifiedText }) }}
    </template>
    <template slot="footer">
      <div class="text-center">
        <button
          type="button"
          class="btn btn-inverse"
          @click="$emit('restore-article-log')"
          data-test="save_failed_btn_restore"
        >
          {{ $t('article.modal.save_failed.restore') }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="$emit('save-article')"
          data-test="save_failed_btn_save"
        >
          {{ $t('article.modal.save_failed.save') }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/shared/Modal'

export default {
  name: 'ArticleSaveFailedModal',
  props: {
    lastModifiedText: {
      type: String,
      required: true
    }
  },
  components: {
    appModal: Modal
  }
}
</script>

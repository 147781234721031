export const availableAiTools = [
  {
    toolId: 'ChatGPT',
    name: 'ChatGPT',
    description: '',
    selected: false
  },
  {
    toolId: 'Perplexity',
    name: 'Perplexity',
    description: '',
    selected: false
  },
  {
    toolId: 'Midjourney',
    name: 'Midjourney',
    description: '',
    selected: false
  },
  {
    toolId: 'DeepL',
    name: 'DeepL',
    description: '',
    selected: false
  },
  {
    toolId: 'Transcription',
    name: 'Prepis rozhovoru na text',
    description: '',
    selected: false
  },
  {
    toolId: 'OtherAiTool',
    name: 'Iné:',
    description: '',
    selected: false
  }
]

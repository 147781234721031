<template>
  <div class="card card-body">
    <h3 class="article-edit-header">{{ $t('article.hp_topic.offer') }}</h3>
    <div
      v-for="(hpTopicOffer, index) in article.hpTopicOffer" :key="index"
      class="row hp-topic-offer-item form-group"
    >
      <app-hp-topic-offer-item
        :hpTopicOffer="hpTopicOffer"
        :department="department"
        :index="index"
        :data-test="`article_hpTopicOffer_${index}`"
      />
      <section class="col-12">
        <button v-if="!articleEditDisabled" class="btn btn-sm btn-danger" @click="remove(hpTopicOffer)" :data-test="`article_hpTopicOffer_${index}_remove`">
          {{ $t('article.hp_topic.remove') }}
        </button>
      </section>
    </div>
    <div>
      <button v-if="!articleEditDisabled" class="btn btn-sm btn-green" @click="add" :data-test="`article_hpTopicOffer_add`">
        {{ $t('article.hp_topic.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import HpTopicOfferItem from './ArticleHpTopicOfferItem'
import HpTopicOffer from '../../model/HpTopicOffer'

export default {
  name: 'HpTopicOffer',
  props: {
    article: {
      type: Object
    }
  },
  components: {
    appHpTopicOfferItem: HpTopicOfferItem
  },
  computed: {
    department () {
      const user = this.$store.getters['user/userById'](this.article.createdBy)
      return user ? user.department : null
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    add () {
      this.article.hpTopicOffer.push(this._.cloneDeep(HpTopicOffer))
    },
    remove (hpTopicOffer) {
      this.article.hpTopicOffer = this.article.hpTopicOffer.filter((item) => item !== hpTopicOffer)
    }
  },
  created () {
    this.$store.dispatch('hpTopicOffer/fetchHolidays')
  }
}
</script>

<style lang="scss" scoped>
.elem-group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.hp-topic-offer-item > div:nth-of-type(n+2) {
  padding-left: 0;
}

.flex-justify-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>

<template>
  <app-modal
    :title="$t('article.modal.article_history_header')"
    modal-size="modal-md"
    close-on-click-self
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <app-article-history :article="article"></app-article-history>
    </template>
  </app-modal>
</template>

<script>
import ArticleHistory from './ArticleHistory'
import Modal from '../shared/Modal'

export default {
  name: 'ArticleHistoryModal',
  props: {
    article: {
      type: Object
    }
  },
  components: {
    appModal: Modal,
    appArticleHistory: ArticleHistory
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

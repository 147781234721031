<template>
  <app-modal
    :title="$t('article.modal.print_publication_header')"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
    </template>

    <!-- Body -->
    <template slot="body">
      <app-print-publication-filter></app-print-publication-filter>
      <app-preloader v-if="callingAPI"></app-preloader>
      <template v-if="dataLoaded">
        <app-data-table
          :data="printPublications"
          :totalCount="totalCount"
          disableMultipleSelect
          actionsInBlankWindow
          showSelectButton
          :page="page"
          :config="dataTableConfig"
          @page-change="setPageAndGetRecords"
          @select-record="selectPrintPublication"
        >
        </app-data-table>
      </template>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import DataTable from '../shared/DataTable'
import Modal from '../shared/Modal'
import PrintPublicationFilter from '../print/PrintPublicationFilter'
import PrintTitleService from '../../services/print/PrintTitleService'

export default {
  name: 'ArticlePrintPublicationModal',
  data () {
    return {
      dataTableConfig: {
        fields: {
          id: this.$t('printPublication.list.id'),
          printTitle: this.$t('printPublication.list.title_title'),
          title: this.$t('printPublication.list.title'),
          publicationDate: this.$t('printPublication.list.publicationDate')
        },
        actions: {
          detail: 'printPublication_detail',
          edit: 'printPublication_edit'
        },
        render: {
          printTitle: function (data) {
            if (data) {
              return this.printTitleById(data)
            }
          }.bind(this)
        }
      },
      dataLoaded: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    printPublications () {
      return this.$store.getters['printPublication/list']
    },
    totalCount () {
      return this.$store.getters['printPublication/totalCount']
    },
    page () {
      return this.$store.getters['printPublication/page']
    }
  },
  components: {
    appModal: Modal,
    appPrintPublicationFilter: PrintPublicationFilter,
    appDataTable: DataTable,
    appPreloader: Preloader
  },
  methods: {
    selectPrintPublication (printPublications) {
      this.$emit('set-data', printPublications)
      this.$emit('close')
    },
    getPrintPublications () {
      this.$store.dispatch('printPublication/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('printPublication/setPage', page)
      this.getPrintPublications()
    },
    resetFilters () {
      const filter = {
        id: '',
        title: ''
      }
      this.$store.commit('articleTest/setPage', 1)
      this.$store.commit('articleTest/setFilter', filter)
    },
    close () {
      this.$emit('close')
    },
    printTitleById (id) {
      return PrintTitleService.getPrintTitleTitle(id)
    }
  },
  beforeMount () {
    this.resetFilters()
    this.getPrintPublications()
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div>
    <div class="form-group" v-if="display">
      <label>{{ $t('article.selected_topics') }}</label>
      <app-multi-select
        :options="topics"
        :value="selectedTopics"
        label="title"
        track-by="id"
        :preselect-first="false"
        :loading="isLoading"
        :internal-search="false"
        :options-limit="300"
        :limit="limit"
        :max-height="600"
        :show-no-results="false"
        :disabled="articleEditDisabled"
        open-direction="bottom"
        id="article_selected_topics"
        @search-change="findTopic"
        @input="updateValueAction"
        :max="limit"
        :multiple="true"
        :hide-selected="true"
        :placeholder="$t('pick_some')"
        :close-on-select="false"
        select-label=""
      >
        <template slot="maxElements">Max {{ limit }}</template>
        <template slot="noOptions">{{ noOptionsText }}</template>
      </app-multi-select>
    </div>
    <p class="small" v-else-if="articleType !== 'external' && limit > 0">
      {{ $t('article.selected_topics_no_keywords')  }}
    </p>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'

export default {
  name: 'ArticleSelectedTopics',
  props: {
    selectedTopics: null,
    articleTags: {
      type: Array
    },
    articleType: {
      type: String
    },
    articleSite: null,
    articleSiteName: {
      type: String
    },
    noOptionsText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedDataTopics: null,
      isLoading: false,
      topics: [],
      searchedTopic: ''
    }
  },
  components: {
    appMultiSelect: MultiSelect
  },
  methods: {
    updateValueAction (data) {
      this.value = data
      this.$emit('set-data', this.value)
    },
    findTopic (query) {
      this.isLoading = true
      this.searchedTopic = ''
      const data = {}
      data.query = query
      data.site = this.articleSite
      data.tagsIds = this.articleTags.map(tag => tag.id)
      this.$store.dispatch('topic/fetchArticleTopic', data)
        .then(() => {
          this.topics = this.$store.getters['topic/articleTopics']
          this.searchedTag = query
          this.isLoading = false
        })
    },
    autoFetch () {
      this.isLoading = true
      const data = {}
      data.query = ''
      data.site = this.articleSite
      data.tagsIds = this.articleTags.map(tag => tag.id)
      this.$store.dispatch('topic/fetchArticleTopic', data)
        .then((response) => {
          this.topics = this.$store.getters['topic/articleTopics']
          this.searchedTag = ''
          this.isLoading = false
        })
    }
  },
  computed: {
    display () {
      return this.articleType !== 'external' && this.limit > 0 && this.articleTags.length > 0
    },
    limit () {
      if (['sport24', 'ncpz', 'wanda', 'zena', 'zdravie'].includes(this.articleSiteName)) {
        return 4
      } else {
        return 0
      }
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  watch: {
    selectedTopics (newData, oldData) {
      if (newData.length !== oldData.length) {
        this.selectedDataTopics = newData
      }
    },
    articleTags (newData, oldData) {
      if (newData.length !== oldData.length) {
        this.autoFetch()
      }
    },
    display (newData) {
      if (newData === true) {
        this.autoFetch()
      }
    }
  },
  created () {
    this.selectedDataTopics = this.selectedTopics
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

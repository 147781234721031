<template>
  <span>
    <div
      v-if="this.activity.user"
      class="alert alert-warning"
    >
      <i class="fa fa-exclamation-circle"></i>
      <strong>{{ getUserInfo(this.activity.user) }}</strong> {{ $t('article.activity_is_editing') }}
    </div>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import CoreApi from '@/api/core'
import moment from 'moment/moment'
import UserMixin from '@/components/mixins/User'

export default {
  name: 'ArticleActivityTracking',
  mixins: [UserMixin],
  props: {
    article: {
      type: Object
    }
  },
  data () {
    return {
      trackingId: 0,
      activity: {},
      lastModifyTime: moment(),
      lastMousePosition: 0,
      watchedChanges: 0
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      trackingInterval: state => state.activity.trackingInterval,
      autoSaveDraftTime: state => state.activity.autoSaveDraftTime
    }),
    articleSerialized () {
      return JSON.stringify(this.article)
    }
  },
  methods: {
    getCurrentActivityForArticle () {
      if (this.article.documentId === undefined) {
        return
      }
      CoreApi().get('/ArticleLock/' + this.article.documentId)
        .then(response => {
          if (response.data.user && this.user.currentUser.username !== response.data.user.username) {
            this.activity = response.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    trackInLoop () {
      this.trackingId = setInterval(() => {
        this.trackUserActivity()
        this.checkLastModifyTime()
      }, this.trackingInterval)
    },
    trackMousePosition (event) {
      const currentMousePosition = event.pageX + '' + event.pageY
      if (this.lastMousePosition !== currentMousePosition) {
        this.lastMousePosition = currentMousePosition
        this.lastModifyTime = moment()
      }
    },
    trackUserActivity () {
      if (!this.article.documentId) {
        return
      }
      this.$store.dispatch('activity/putArticleActivity', {
        articleDocumentId: this.article.documentId,
        articleId: this.article.id
      }).catch((error) => {
        console.error(error)
      })
    },
    checkLastModifyTime () {
      if (moment().diff(this.lastModifyTime) > this.autoSaveDraftTime) {
        this.$emit('auto-save')
      }
    },
    deleteActivity () {
      if (!this.article.documentId) {
        return
      }
      CoreApi().get('/ArticleLock/' + this.article.documentId)
        .then(response => {
          if (response.data.id) {
            CoreApi().delete('/ArticleLock/' + response.data.id)
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getCurrentActivityForArticle()
    this.trackUserActivity()
    this.trackInLoop()
    window.addEventListener('mousemove', this.trackMousePosition, false)
  },
  beforeDestroy () {
    clearInterval(this.trackingId)
    this.getCurrentActivityForArticle()
    this.deleteActivity()
  },
  watch: {
    articleSerialized () {
      this.watchedChanges++
      if (this.watchedChanges > 2) {
        this.canCloseArticle = false
        this.lastModifyTime = moment()
      }
    }
  }
}
</script>

<!---------------------------------------------------------------------------------------->
<!-- More information about this: -------------------------------------------------------->
<!-- https://newsandmedia.atlassian.net/wiki/spaces/EE/pages/2893217793/Geneea+Tags+API -->
<!---------------------------------------------------------------------------------------->
<template>
  <div class="geneea-tags">
    <h5 class="geneea-tags__desc">{{ $t('article.tags_geneea') }}</h5>
    <MultiSelect
      v-model="article.geneeaTags"
      :placeholder="$t('type_keyword')"
      :options="geneeaTags"
      :loading="loading"
      :custom-label="getLabel"
      :limit="999"
      :disabled="articleEditDisabled"
      :error="error"
      track-by="geneeaId"
      id="article_rubrics"
      @search-change="findTags"
      @remove="onRemoveTag"
    />
    <button
      v-if="!articleEditDisabled"
      :class="['geneea-tags__generate', { 'geneea-tags__generate--loading' : loading }]"
      @click="generateNewTags"
      type="button"
    >
      <template v-if="!article.geneeaTags.length">{{ $t('article.tags_geneea_generate') }}</template>
      <template v-if="article.geneeaTags.length">{{ $t('article.tags_geneea_regenerate') }}</template>
    </button>
  </div>
</template>

<script>
import CoreApi from '@/api/core'
import MultiSelect from '@/components/form/select/MultiSelect'

export default {
  name: 'GeneeaTags',
  props: {
    article: {
      type: Object,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MultiSelect
  },
  data () {
    return {
      loading: false,
      geneeaTags: [],
      removedTags: {} // map of geneeaId
    }
  },
  computed: {
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    getLabel (tag) {
      return `${tag.title} (${tag.type})`
    },
    async findTags (query) {
      if (query) {
        await this.$store.dispatch('tag/fetchByTitleGeneea', { query, view: 'article' })
        this.geneeaTags = this.$store.getters['tag/listGeneea']
      } else {
        this.geneeaTags = []
      }
    },
    // ***************************************************************************************************************
    //   After click it will generate new tags from:
    // - Article title
    // - Article perex
    // - Article body (only from the new nmh-editor)
    // ***************************************************************************************************************
    async generateNewTags () {
      this.loading = true

      // Data that we will send
      const articleId = parseInt(this.article.id) // Article ID - integer
      const rubricId = parseInt(this.article.rubrics[0].id) // Rubric ID - integer
      const articleTitle = this.article.field.title
      const language = 'cs'

      CoreApi().post('/ArticleBodyConverter/articleBlocksToHtmlConversion', { blocks: this.$store.state.nmhEditorStore.data.blocks }).then(res => {
        CoreApi().post('/geneeaApiCall', {
          id: articleId, // Article ID (integer)
          rubricId, // Rubric ID (integer)
          title: articleTitle, // Article title (string)
          body: res.data, // Article body blocks (array of blocks)
          language: language // Article lang (string) E.g.: 'cs', 'sk', 'en'
        })
          .then(res => {
            const existingTags = this.article.geneeaTags.reduce((acc, tag) => {
              acc[tag.geneeaId] = tag
              return acc
            }, {})

            const generatedTags = res.data
            generatedTags.forEach(tag => {
              if (!existingTags[tag.geneeaId] && !this.removedTags[tag.geneeaId]) {
                this.article.geneeaTags.push(tag)
              }
            })

            this.loading = false
          })
          .catch(error => {
            console.error(error)
            this.loading = false
          })
      })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
    onRemoveTag (removedTag) {
      this.removedTags[removedTag.geneeaId] = removedTag
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/components/GeneeaTags/GeneeaTags.scss";
</style>

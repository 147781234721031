<template>
  <div class="card card-body">
    <h3>{{ $t('article.notificationService.notification') }}</h3>
    <div>
        {{ siteTitle }}
        {{ `-` }}
        {{ $t('article.notificationService.remainingFromLimit', { remaining, limit }) }}
    </div>
    <OutlinedCheckbox
      v-model="article.oneSignalNotified"
      id="publish_notification"
      :label="$t('article.notificationService.oneSignalNotified')"
      :disabled="articleEditDisabled"
    />
    <OutlinedCheckbox
      v-if="article.oneSignalNotified"
      v-model="article.notificationPostponed"
      id="publish_delayed_notification"
      :label="$t('article.notificationService.notificationPostponed')"
      :disabled="articleEditDisabled"
    />
  </div>
</template>

<script>

import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import { mapGetters } from 'vuex'
export default {
  name: 'NotificationServiceArticleNotification',
  props: {
    article: {
      type: Object
    }
  },
  components: {
    OutlinedCheckbox
  },
  data () {
    return {
      limit: null,
      remaining: null
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    site () {
      return this.$store.getters['site/siteById'](this.article.site)
    },
    siteTitle () {
      return this.site?.title
    },
    siteId () {
      return this.vlm ? this.site?.entityUuid : this.site?.id
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  methods: {
    async getRemainingNotification () {
      const { limit, remaining } = await this.$store.dispatch(
        'notificationService/getRemainingNotification', this.siteId)
      this.limit = limit
      this.remaining = remaining
    }
  },
  watch: {
    'article.oneSignalNotified' (newVal) {
      if (!newVal) {
        this.article.notificationPostponed = false
      }
    }
  },
  async created () {
    await this.getRemainingNotification()
  }
}
</script>

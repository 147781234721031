<template>
  <div></div>
</template>
<script>
export default {
  name: 'KeydownCtrlS',
  methods: {
    callAction (event) {
      if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
        event.preventDefault()
        this.$emit('action')
      }
    }
  },
  created () {
    document.addEventListener('keydown', this.callAction, false)
  }
}
</script>

<template>
  <button
    type="button"
    class="btn btn-success"
    @click="$emit('click', $event.target.value)"
  >
    <i class="fa fa-save "></i> <span class="hidden-sm-down">{{ $t('buttons.save_draft') }}</span>
  </button>
</template>

<script>
export default {
  name: 'ArticleSaveDraftButton',
  data () {
    return {}
  }
}
</script>

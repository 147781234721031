<template>
  <section>
    <div class="form-group">
      <app-checkbox-button
        v-if="article.type === 'external'"
        v-model="article.flag.video"
        id="article_flag_video"
        :label="$t('article.video')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-if="false"
        v-model="article.flag.imageGallery"
        id="article_flag_imageGallery"
        :label="$t('article.image_gallery')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
          v-if="article.site === 18"
          v-model="article.flag.unlocked"
          id="article_flag_unlocked"
          :label="$t('article.unlocked')"
          :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-model="article.flag.exclusive"
        id="article_flag_exclusive"
        :label="$t('article.exclusive')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-model="article.flag.commentary"
        id="article_flag_commentary"
        :label="$t('article.commentary')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-model="article.flag.interview"
        id="article_flag_interview"
        :label="$t('article.interview')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-model="article.flag.online"
        id="article_flag_online"
        :label="$t('article.online')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-model="article.flag.updated"
        id="article_flag_updated"
        :label="$t('article.updated')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-model="article.flag.justNow"
        id="article_flag_justNow"
        :label="$t('article.just_now')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-if="article.type === 'external'"
        v-model="article.flag.poll"
        id="article_flag_poll"
        :label="$t('article.poll')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-if="article.type === 'external'"
        v-model="article.flag.photostory"
        id="article_flag_photostory"
        :label="$t('article.photostory')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-if="article.type === 'external'"
        v-model="article.flag.quiz"
        id="article_flag_quiz"
        :label="$t('article.quiz')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
      <app-checkbox-button
        v-if="article.type === 'external'"
        v-model="article.flag.likeDislike"
        id="article_flag_likeDislike"
        :label="$t('article.like_dislike')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox-button>
    </div>
    <div class="row">
      <div class="col-lg-12" v-if="article.flag.updated">
        <app-datepicker
          v-model="article.flag.updatedUntilDatetime"
          id="article_updatedUntilDatetime"
          :label="$t('article.updated_until_datetime')"
          :disabled="articleEditDisabled"
        >
        </app-datepicker>
      </div>
      <div class="col-lg-12" v-if="article.flag.justNow">
        <app-datepicker
          v-model="article.flag.justNowUntilDatetime"
          id="article_justNowUntilDatetime"
          :label="$t('article.just_now_until_datetime')"
          :disabled="articleEditDisabled"
        >
        </app-datepicker>
      </div>
    </div>
  </section>
</template>

<script>
import CheckboxButton from '../form/inputs/InputCheckBox'
import Datepicker from '../form/Datepicker'

export default {
  name: 'FlagButtons',
  props: {
    article: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    updated () {
      return this.article.flag.updated
    },
    justNow () {
      return this.article.flag.justNow
    },
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    }
  },
  watch: {
    updated (updated) {
      if (updated === false) {
        this.article.flag.updatedUntilDatetime = ''
      }
    },
    justNow (justNow) {
      if (justNow === false) {
        this.article.flag.justNowUntilDatetime = ''
      }
    }
  },
  components: {
    appCheckboxButton: CheckboxButton,
    appDatepicker: Datepicker
  }
}
</script>
